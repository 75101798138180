/**
 * Provides commonly-used functions relating to notes
 */
import {
  NOTE_CHANGE,
  NOTE_REQUEST,
  NOTE_PICKS_UP,
  NOTE_SETS_DOWN,
  NOTE_CLASS,
  NOTE_CATERING,
  NOTE_RESERVATION,
  NOTE_VARIANCE,
  NOTE_DATES_OPERATION,
  NOTE_DAYS_DOES_NOT_RUN,
  NOTE_DAYS_DOES_RUN,
  NOTE_CUSTOM,
  NOTE_DESTINATION,
  NOTE_ORIGIN,
  NOTE_ALSO_CALLS,
  GLOBAL_NOTES,
  FACILITIES_NOTES,
} from './constants'

import moment from 'moment'
import { symbols, symbolTranslations } from './symbols'

// Given an array of day indexes, provide a human-readable list
// e.g. [0, 2, 6] => "Mondays, Wednesdays and Sundays"
const translateDays = (days) => {
  const dayStrings = ['Mondays', 'Tuesdays', 'Wednesdays', 'Thursdays', 'Fridays', 'Saturdays', 'Sundays']
  const namedDays = days.map(dayIndex => dayStrings[dayIndex])
  return joinAnd(namedDays)
}

// Given a dates of operation note, return a human-readable description
// e.g. [['2018-07-01', '2018-08-17'], ['2018-12-02', '2018-12-10']]
//   => 'Operates from 1st July to 17th August and 2nd to 12th December
const renderDatesOfOperationText = (note, context) => {
  if (note.periods) {
    const periods = note.periods.map((dates) => {
      const [dateFrom, dateTo] = dates.map(date => moment(date))
      if (dateFrom.format('MM') === dateTo.format('MM')) {
        return `from ${dateFrom.format('Do')} to ${dateTo.format('Do MMMM')}`
      } else {
        return `from ${dateFrom.format('Do MMMM')} to ${dateTo.format('Do MMMM')}`
      }
    })
    return `${note.note_type.text} ${joinAnd(periods)}`
  } else {
    return `${context && context.isConnection ? 'Connection applies' : 'Operates'} ${note.duration.text} ${moment(note.date).format('Do MMMM')}`
  }
}

/**
 * Given a note, provide a human-readable translation of its definition. This
 * output varies wildly depending on the type of note being translated: custom
 * notes simply return their text, request stop notes return a TOC-specific
 * label, dates of operation notes return descriptions of their active dates.
 */
export const noteTypeHandler = (note) => {
  switch (note.note_type.var) {
    case 'location':
      switch (`${note.note_type.type}`) {
        case NOTE_CHANGE:
          return `${note.note_type.text} ${note.location.name}`
        case NOTE_REQUEST:
          return 'Stops on request. Please tell the Conductor if you wish to leave. Please signal to the Driver if you wish to board'
        case NOTE_PICKS_UP:
          return 'Stops to pick up passengers only'
        case NOTE_SETS_DOWN:
          return 'Stops to set down passengers only'
        case NOTE_CUSTOM:
          return note.text
        case NOTE_DATES_OPERATION:
          return renderDatesOfOperationText(note, { isConnection: true })
        case NOTE_VARIANCE: {
          const base = `Calls ${note.variance} minutes`
          const variance = note.variance < 0 ? `earlier` : `later`
          const from = note.valid_from ? `from ${moment(note.valid_from).format('D MMMM')}` : ''
          const to = note.valid_to ? `until ${moment(note.valid_to).format('D MMMM')}` : ''
          const days = note.days.length ? `on ${translateDays(note.days)}` : ''
          // added regex to remove -
          return [base, variance, days, from, to].filter(x => !!x).join(' ').replace(/-/, '')
        }
        default:
          return note.note_type.text
      }
    case 'column':
      switch (`${note.note_type.type}`) {
        case NOTE_CATERING:
          return note.note.split('').map(char => {
            const symbolChar = symbolTranslations[NOTE_CATERING][char]
            return symbols[symbolChar].title
          }).join(',')
        case NOTE_RESERVATION:
        case NOTE_CLASS: {
          const symbolChar = symbolTranslations[`${note.note_type.type}`][note.note]
          return symbols[symbolChar] ? symbols[symbolChar].title : ''
        }
        case NOTE_DATES_OPERATION:
          return renderDatesOfOperationText(note)
        case NOTE_DAYS_DOES_NOT_RUN:
          return `${note.note_type.text} ${translateDays(note.days)}`
        case NOTE_DAYS_DOES_RUN:
          return `${note.note_type.text} ${translateDays(note.days)}`
        case NOTE_ALSO_CALLS:
          return `${note.note_type.text} ${note.location.name} ${note.time}`
        case NOTE_ORIGIN:
        case NOTE_DESTINATION:
          return note.text
        case NOTE_CUSTOM:
          return note.text
        default:
          return note.note_type.text
      }
    default:
      return note.note_type.text
  }
}

/**
 * Returns a generator to enumerate notes in a timetable, excluding any provided
 * characters. Starts at 'A' through 'Z', then 'AA' through 'AZ', then 'BA' etc.
 * If `type` is set to 'lower', all return characters are lower case.
 */
export function * footnoteGenerator (type = 'upper', exclude = Object.keys(GLOBAL_NOTES)) {
  if (type !== 'upper' && type !== 'lower') throw new Error('Invalid note type')

  const startingValue = type === 'upper' ? 65 : 97

  // Go through the alphabet, assigning note codes
  for (var i = startingValue - 1; i < startingValue + 26; i++) {
    for (var t = startingValue; t < startingValue + 26; t++) {
      const code = (i >= startingValue ? String.fromCharCode(i) : '') + String.fromCharCode(t)

      // Don't assign global notes
      if (!exclude.includes(code)) {
        yield code
      }
    }
  }
}

/**
 * Join an array of strings with commas, but join the last two with the word
 * "and". Used to generate more human-readable lists.
 */
export function joinAnd (arr) {
  const parts = [arr.slice(-1)]

  if (arr.length > 1) {
    parts.unshift(arr.slice(0, arr.length - 1).join(', '))
  }

  return parts.join(' and ')
}

/**
 * Identify whether the supplied note denotes a train facility (and hence should
 * use a symbol)
 */
export function isFacilityNote (note) {
  return (note.note && note.note.substr(0, 1) === '#') || (note.note_type && FACILITIES_NOTES.includes(note.note_type.type.toString()))
}
