import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import Spinner from 'components/Spinner'

const FullPageSpinner = ({ classes }) => (
  <div className={classes.fullPage}>
    <Spinner />
  </div>
)

FullPageSpinner.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles({
  fullPage: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})(FullPageSpinner)
