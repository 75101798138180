/**
 * A collection of date-related constants and functions, a lot of which is
 * forked from http://stackoverflow.com/questions/497790
 *
 * Used throughout the application, mostly to convert between objects of days
 * and an English description (e.g. {mon: true, tue: true, ...}) to "Mondays to
 * Sundays"
 */
var dates = {
  SSUX: {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: false,
    sun: false,
  },
  SO: {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: true,
    sun: false,
  },
  SU: {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: true,
  },
  SUX: {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: false,
  },
  days_words: {
    mon: 'Mondays',
    tue: 'Tuesdays',
    wed: 'Wednesdays',
    thu: 'Thursdays',
    fri: 'Fridays',
    sat: 'Saturdays',
    sun: 'Sundays',
  },
  getDayCode: day => {
    const daysCodes = {
      mon: 'M',
      tue: 'T',
      wed: 'W',
      thu: 'Th',
      fri: 'F',
      sat: 'S',
      sun: 'Su',
    }
    return daysCodes[day]
  },
  getDayWord: day => {
    const daysWords = {
      mon: 'Mondays',
      tue: 'Tuesdays',
      wed: 'Wednesdays',
      thu: 'Thursdays',
      fri: 'Fridays',
      sat: 'Saturdays',
      sun: 'Sundays',
    }
    return daysWords[day]
  },
  getCodes: (days, _this) => {
    let codes = ''
    for (var i = 0; i < days.length; i++) {
      codes += _this.getDayCode(days[i])
    }
    return codes
  },
  getWords: (days, _this) => {
    return (
      days.length > 1
        ? `${_this.getDayWord(days[0])} ${(days.length === 2 ? 'and' : 'to')} ${_this.getDayWord(days[days.length - 1])}`
        : _this.getDayWord(days[0])
    )
  },
  checkDays: (daysRun, _this, activeDays = {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
  }) => {
    let daysRunOrdered = {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    }
    let daysW = Object.keys(daysRunOrdered)
    for (var i = daysW.length - 1; i >= 0; i--) {
      daysRunOrdered[daysW[i]] = daysRun[daysW[i]]
    }

    const activeDaysRun = Object.keys(daysRunOrdered).filter(key => activeDays[key])
    const contains = activeDaysRun.filter((key) => (daysRunOrdered[key]))
    const excludes = activeDaysRun.filter((key) => (!daysRunOrdered[key]))

    const codes = (contains.length < excludes.length ? _this.getCodes(contains, _this) : _this.getCodes(excludes, _this))

    return `${codes}${(contains.length < excludes.length ? 'O' : 'X')}`
  },
  checkDaysSemantic: (daysRun, _this) => {
    let daysRunOrdered = {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    }
    let daysW = [
      'mon',
      'tue',
      'wed',
      'thu',
      'fri',
      'sat',
      'sun',
    ]
    for (var i = daysW.length - 1; i >= 0; i--) {
      daysRunOrdered[daysW[i]] = daysRun[daysW[i]]
    }
    let contains = Object.keys(daysRunOrdered).filter((key) => (daysRunOrdered[key]))
    return `${_this.getWords(contains, _this)}`
  },
}

export default dates

/**
 * Move `inDate` forwards or backwards until it falls on one of the `validDays`
 */
export const clampDate = (inDate, validDays, options) => {
  options = Object.assign({ backwards: false }, options)

  // Clone date to avoid mutation
  const date = new Date(inDate.getTime())
  const modifier = options.backwards ? -1 : 1

  const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

  // If the date does not fall amongst the valid days, shift it forwards or
  // backwards by a day until it is. NB: `gate.getDay()` runs Sunday (0) to
  // Saturday (6), hence the offset array above to facilitate lookup
  while (validDays[days[date.getDay()]] === false) {
    date.setDate(date.getDate() + modifier)
  }

  return date
}
