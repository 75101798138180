/**
 * A stylised version of the `react-md-spinner` component, using Times2 branding
 */
import React from 'react'
import MDSpinner from 'react-md-spinner'
import PropTypes from 'prop-types'

const propTypes = {
  size: PropTypes.number,
}

const defaultProps = {
  size: 60,
}

const Spinner = ({ size }) => (
  <MDSpinner
    size={size}
    color1="rgb(96, 125, 139)"
    color2="rgb(245, 0, 87)"
    color3="rgb(38, 50, 56)"
    color4="rgb(96, 125, 139)"
  />
)

Spinner.propTypes = propTypes
Spinner.defaultProps = defaultProps

export default Spinner
