import { connect } from 'react-redux'

import { forceRefresh } from 'store/reference'
import { logout } from 'store/auth'

import AppToolbar from 'components/AppToolbar'

const mapStateToProps = (state) => ({
  isAdmin: !!state.auth.user && state.auth.user.isAdmin,
})

const mapDispatchToProps = (dispatch) => ({
  onRefreshData: () => {
    dispatch(forceRefresh())
  },
  onLogout: () => {
    dispatch(logout())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AppToolbar)
