/**
 * Wrapper for react-helmet providing default title values
 */
import React from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet'

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {
  children: null,
}

const MyHelmet = ({ children }) => (
  <Helmet
    defaultTitle="Times²"
    titleTemplate="%s - Times²"
  >
    { children }
  </Helmet>
)

MyHelmet.propTypes = propTypes
MyHelmet.defaultProps = defaultProps

export default MyHelmet
