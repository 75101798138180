import UserController from 'thunks/classes/User'
import browserHistory from 'helpers/history'
import localForage from 'localforage'

/**
 * This handles authentication and authorisation for the application, keeping
 * track of the currently logged in user and their access token.
 */

export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED'
export const ASK_FOR_LOGIN = 'ASK_FOR_LOGIN'

// Define reusable thunks
const userThunk = new UserController()

// This is an interval timeout which regularly checks if the current user's
// session has expired.
let expiryInterval

export function checkIfLoggedIn () {
  return async (dispatch) => {
    const value = await localForage.getItem('times2User')

    if (value) {
      const user = JSON.parse(value)

      if (new Date() < new Date(user.validity)) {
        return user
      }
    }

    throw new Error('Not logged in')
  }
}

export function autoLogin () {
  return async (dispatch) => {
    try {
      const user = await dispatch(checkIfLoggedIn())

      dispatch({
        type: LOGIN_SUCCESSFUL,
        payload: user,
      })

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: `Welcome ${user.email}`,
      })

      dispatch(waitForExpiry())
    } catch (e) {
      dispatch({
        type: ASK_FOR_LOGIN,
      })
    }
  }
}

export function login (credentials) {
  return async (dispatch) => {
    dispatch({
      type: LOGIN,
      payload: credentials,
    })

    try {
      const userThunk = new UserController(credentials.user, credentials.pwd)
      const { data: user } = await userThunk.login()
      const { data: userData } = await userThunk.view(null, user.token)

      // Identify whether the user is an admin
      // NB: This should never be relied on as it's trivially changed on the
      // front end.
      if (userData.admin) {
        user.isAdmin = true
      }

      dispatch({
        type: LOGIN_SUCCESSFUL,
        payload: user,
      })

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: `Welcome ${user.email}`,
      })

      localForage.setItem('times2User', JSON.stringify(user))

      dispatch(waitForExpiry())
    } catch (e) {
      dispatch({
        type: ASK_FOR_LOGIN,
      })
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: `Invalid credentials`,
      })
    }
  }
}

export function waitForExpiry () {
  return (dispatch, getState) => {
    const checkIfExpired = () => {
      const tokenExpiry = getState().auth && getState().auth.user ? new Date(getState().auth.user.validity) : null

      if (!tokenExpiry || tokenExpiry < new Date()) {
        clearInterval(expiryInterval)
        dispatch({ type: TOKEN_EXPIRED })
        dispatch({ type: ASK_FOR_LOGIN })
      }
    }

    // Check every second, and run immediately
    expiryInterval = setInterval(checkIfExpired, 1000)
    checkIfExpired()
  }
}

export function changePassword (oldPassword, newPassword) {
  return async (dispatch, getState) => {
    try {
      await userThunk.changePassword(oldPassword, newPassword, getState().auth.user.token)

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: 'Password successfully updated',
      })

      return true
    } catch (err) {
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: err.message,
      })

      return false
    }
  }
}

export function requestPasswordReset (email) {
  return async (dispatch) => {
    try {
      await userThunk.resetPassword(email)

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: `Password reset e-mail sent to ${email}`,
      })
    } catch {
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: 'An error occurred whilst requesting a password reset. Please contact your account manager.',
      })
    }
  }
}

export function resetPassword (email, code, newPassword) {
  return async (dispatch) => {
    try {
      await userThunk.resetPassword(email, code, newPassword)

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: `Your password has been reset successfully, please login!`,
      })

      browserHistory.push('/')
    } catch (err) {
      let message = 'An error occurred whilst resetting your password. Please try again. If the problem persists, contact your account manager for further assistance.'

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: err.message || message,
      })
    }
  }
}

export function logout () {
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
    })

    setTimeout(() => location.reload(), 500)
  }
}

const ACTION_HANDLERS = {
  [LOGIN]: (state) => {
    return { ...state, loading: true }
  },
  [LOGIN_SUCCESSFUL]: (state, action) => {
    return { ...state, user: action.payload, modalOpen: false }
  },
  [ASK_FOR_LOGIN]: (state) => {
    return { ...state, user: null, modalOpen: true, loading: false }
  },
  [TOKEN_EXPIRED]: (state) => {
    return { ...state, hasExpired: true }
  },
  [LOGOUT]: (state) => {
    localForage.clear()
    return { ...state, user: null, modalOpen: true }
  },
}

const initialState = { user: null, modalOpen: false, loading: false, hasExpired: false }
export default function authReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
