import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// MUI Components
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import Spinner from 'components/Spinner'
import UserDialog from 'components/UserDialog'

const styleSheet = ({ palette }) => ({
  actionButton: {
    background: palette.primary['900'],
    width: '100%',
    textAlign: 'center',
    color: 'white',
    margin: 0,
    padding: '25px 0',
  },
})

class LoginDialog extends Component {
  constructor (props) {
    super(props)
    this.props.checkIfLoggedIn()
      .then(this.props.autoLogin)
  }

  state = {
    username: '',
    password: '',
    forgotPassword: false,
    email: '',
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleEmailChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  handleLogin = () => {
    if (this.state.username !== '' && this.state.password !== '') {
      this.props.login({ user: this.state.username, pwd: this.state.password })
    }
  }

  toggleForgotPassword = () => {
    this.setState(prevState => ({
      forgotPassword: !prevState.forgotPassword,
    }))
  }

  handleRequestPasswordResetEmail = () => {
    this.props.requestPasswordReset(this.state.email)
  }

  render () {
    const { auth, classes } = this.props
    const { forgotPassword } = this.state

    let dialogContent
    let dialogActions

    if (!forgotPassword) {
      dialogContent = (
        <Fragment>
          { auth.hasExpired &&
          <Typography paragraph>Your session has expired. Please log in again.</Typography>
          }
          <TextField
            name="username"
            label="Username"
            onChange={this.handleChange}
            fullWidth
            margin="normal"
            autoFocus
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            onChange={this.handleChange}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" onClick={this.toggleForgotPassword} style={{ marginTop: 50 }}>
            Forgot password?
          </Button>
        </Fragment>
      )
    } else {
      dialogContent = <Fragment>
        <Typography variant="h6" gutterBottom>Password reset</Typography>
        <Typography variant="body1" gutterBottom>Forgotten your password? No problem, just enter your email address below and we&#039;ll send you a link to reset it.</Typography>
        <TextField
          type="email"
          // name="email"
          label="Email address"
          // Changed the uncontrolled input to be controlled
          // React would reuse the Password textfield and display the user's password in plaintext on the email section
          value={this.state.email}
          onChange={this.handleEmailChange('email')}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={this.handleRequestPasswordResetEmail} style={{ marginTop: 32 }}>
            Send password reset email
        </Button>
      </Fragment>
    }

    if (!this.props.auth.loading && !this.state.forgotPassword) {
      dialogActions = (
        <Button
          classes={{ root: classes.actionButton }}
          onClick={this.handleLogin}
          color="primary"
          variant="contained">
          Login
        </Button>
      )
    }

    return (
      <UserDialog open={auth.modalOpen} actions={dialogActions} onSubmit={forgotPassword ? this.handleRequestPasswordResetEmail : this.handleLogin}>
        <div hidden={this.props.auth.loading}>
          {dialogContent}
        </div>
        <div hidden={!this.props.auth.loading}>
          <Spinner size={70} />
        </div>
      </UserDialog>
    )
  }
}

LoginDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  checkIfLoggedIn: PropTypes.func.isRequired,
  auth: PropTypes.exact({
    user: PropTypes.object,
    modalOpen: PropTypes.bool,
    loading: PropTypes.bool,
    hasExpired: PropTypes.bool,
  }).isRequired,
  autoLogin: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  requestPasswordReset: PropTypes.func.isRequired,
}

export default withStyles(styleSheet)(LoginDialog)
