/**
 * API handlers for managing timetables, including services, columns and times
 */
import { makeRequest, buildResponse, buildError } from 'helpers/requestHandler'

export default {
  // First API call after logging in to get list of tables
  async list (key) {
    const response = await makeRequest('/timetables/tables/list', { key })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async create (name, description, bank, dateFrom, dateTo, daysRun, overnight, dataFile, filters, key) {
    const response = await makeRequest('/timetables/tables/create', {
      key,
      bank_id: bank,
      date_from: dateFrom,
      date_to: dateTo,
      days_run: daysRun,
      overnight,
      data_file: dataFile,
      name,
      description,
      filters: (filters != null ? filters : undefined),
    })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        case 404:
          return buildError(response, 'The requested data file cannot be found')
        case 410:
          return buildError(response, 'The requested bank is not valid')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async delete (id, key) {
    const response = await makeRequest('/timetables/tables/delete', { key, 'unique_id': id })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        case 404:
          return buildError(response, 'Table cannot be found')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async undelete (id, key) {
    const response = await makeRequest('/timetables/tables/undelete', { key, 'unique_id': id })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        case 404:
          return buildError(response, 'Table cannot be found')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async view (id, key) {
    const response = await makeRequest('/timetables/tables/view', { key, 'unique_id': id })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        case 404:
          return buildError(response, 'Table does not exist ')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async checkStatus (id, key) {
    const response = await makeRequest('/timetables/tables/status', { key, 'unique_id': id })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Operation not permitted')
        case 410:
          return buildError(response, 'Table does not exist')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async dataList (key) {
    const response = await makeRequest('/timetables/tables/datafiles', { key })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async queueConnections (timetableId, key) {
    const response = await makeRequest('/timetables/tables/queue_connections', { key, 'unique_id': timetableId })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 400:
          return buildError(response, 'Table ID is missing')
        case 404:
          return buildError(response, 'Table does not exist')
        case 401:
          return buildError(response, 'Table not ready for connections')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async regenerate (timetableId, key) {
    const response = await makeRequest('/timetables/tables/regenerate', {
      'key': key,
      'unique_id': timetableId,
    })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 400:
          return buildError(response, 'Table ID is missing')
        case 404:
          return buildError(response, 'Table does not exist')
        case 401:
          return buildError(response, 'Table cannot be regenerated')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async edit (timetableId, data, key) {
    const response = await makeRequest('/timetables/tables/edit', {
      'key': key,
      'unique_id': timetableId,
      ...data,
    })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 400:
          return buildError(response, 'Table ID is missing')
        case 404:
          return buildError(response, 'Table does not exist')
        case 401:
          return buildError(response, 'Table cannot be edited')
        case 409:
          try {
            const data = await response.json()
            if (data.message.includes('state')) {
              // Force custom error message
              throw new Error('Timetable not currently editable, please wait for processing to finish and try again')
            } else if (data.message) {
              throw new Error(data.message)
            } else {
              throw new Error('Table cannot be edited')
            }
          } catch (e) {
            throw e
          }
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  service: {
    // eslint-disable-next-line camelcase
    async create (timetable_id, uid, reporting_id, atoc_code, dates, key) {
      const response = await makeRequest('/timetables/tables/service/create', {
        key,
        timetable_id,
        uid,
        reporting_id,
        atoc_code,
        dates,
      })

      if (response.ok) {
        return buildResponse(response)
      } else {
        switch (response.status) {
          case 400:
            return buildError(response, 'Missing fields')
          case 404:
            return buildError(response, 'Cannot find Table or Column')
          case 403:
            return buildError(response, 'No permissions to access feature')
          default:
            return buildError(response, 'Unknown')
        }
      }
    },
    // eslint-disable-next-line camelcase
    async edit (id, data, key) {
      const response = await makeRequest('/timetables/tables/service/edit', { id, ...data, key })

      if (response.ok) {
        return buildResponse(response)
      } else {
        switch (response.status) {
          case 400:
            return buildError(response, 'Missing fields')
          case 404:
            return buildError(response, 'Cannot find Table or Column')
          case 403:
            return buildError(response, 'No permissions to access feature')
          default:
            return buildError(response, 'Unknown')
        }
      }
    },
  },

  column: {
    async move (timetableId, columnId, newPositionColumnId, key) {
      const response = await makeRequest('/timetables/tables/column/move', {
        key,
        timetable_id: timetableId,
        column_id: columnId,
        new_position: newPositionColumnId,
      })

      if (response.ok) {
        return buildResponse(response)
      } else {
        switch (response.status) {
          case 400:
            return buildError(response, 'Missing fields')
          case 404:
            return buildError(response, 'Cannot find Table or Column')
          case 403:
            return buildError(response, 'No permissions to access feature')
          case 409:
            return buildError(response, 'Update not possible')
          case 410:
            return buildError(response, 'Table or Column does not exist')
          default:
            return buildError(response, 'Unknown')
        }
      }
    },
    // eslint-disable-next-line camelcase
    async edit (timetable_id, column_id, data, key) {
      const response = await makeRequest('/timetables/tables/column/edit', {
        key,
        timetable_id,
        column_id,
        data,
      })

      if (response.ok) {
        return buildResponse(response)
      } else {
        switch (response.status) {
          case 400:
            return buildError(response, 'Missing fields')
          case 404:
            return buildError(response, 'Cannot find Table or Column')
          case 403:
            return buildError(response, 'No permissions to access feature')
          default:
            return buildError(response, 'Unknown')
        }
      }
    },
    // eslint-disable-next-line camelcase
    async suppress (timetable_id, column_id, key) {
      const response = await makeRequest('/timetables/tables/column/suppress', { timetable_id, column_id, key })

      if (response.ok) {
        return buildResponse(response)
      } else {
        switch (response.status) {
          case 400:
            return buildError(response, 'Missing fields')
          case 404:
            return buildError(response, 'Cannot find Table or Column')
          case 403:
            return buildError(response, 'No permissions to access feature')
          case 409:
            return buildError(response, 'Update not possible')
          case 410:
            return buildError(response, 'Table or Column does not exist')
          default:
            return buildError(response, 'Unknown')
        }
      }
    },
    // eslint-disable-next-line camelcase
    async delete (timetable_id, column_id, key) {
      const response = await makeRequest('/timetables/tables/column/delete', { timetable_id, column_id, key })

      if (response.ok) {
        return buildResponse(response)
      } else {
        switch (response.status) {
          case 400:
            return buildError(response, 'Missing fields')
          case 404:
            return buildError(response, 'Cannot find Table or Column')
          case 403:
            return buildError(response, 'No permissions to access feature')
          case 409:
            return buildError(response, 'Delete not possible')
          case 410:
            return buildError(response, 'Table or Column does not exist')
          default:
            return buildError(response, 'Unknown')
        }
      }
    },
    async create (timetableId, newPositionColumnId, key) {
      const response = await makeRequest('/timetables/tables/column/create', {
        key,
        'timetable_id': timetableId,
        'new_position': newPositionColumnId,
      })

      if (response.ok) {
        return buildResponse(response)
      } else {
        switch (response.status) {
          case 400:
            return buildError(response, 'Missing fields')
          case 404:
            return buildError(response, 'Cannot find Table or Column')
          case 403:
            return buildError(response, 'No permissions to access feature')
          case 409:
            return buildError(response, 'Update not possible')
          case 410:
            return buildError(response, 'Table or Column does not exist')
          default:
            return buildError(response, 'Unknown')
        }
      }
    },
    async clone (timetableId, sourceColumn, service, newPositionColumnId, key) {
      // Create column
      const response = await makeRequest('/timetables/tables/column/create', {
        key,
        'timetable_id': timetableId,
        'new_position': newPositionColumnId,
      })

      if (!response.ok) {
        switch (response.status) {
          case 400:
            return buildError(response, 'Missing fields')
          case 404:
            return buildError(response, 'Cannot find Table or Column')
          case 403:
            return buildError(response, 'No permissions to access feature')
          case 409:
            return buildError(response, 'Update not possible')
          case 410:
            return buildError(response, 'Table or Column does not exist')
          default:
            return buildError(response, 'Unknown')
        }
      }

      const json = await response.json()
      // TODO fix this response.json thing

      await Promise.all(sourceColumn.timings.map((timing) => {
        return this.timing.edit(
          timetableId,
          json.column_id,
          {
            ...timing,
            service: {
              id: service.id,
              uid: service.uid,
            },
          },
          key,
          true
        )
      }))

      // Copy notes to new column
      const result = await this.edit(timetableId, json.column_id,
        { notes: sourceColumn.notes },
        key)

      // Return the response of `.edit`, which contains a full copy of the column
      return buildResponse(result.data)
    },
    timing: {
      async add (timetableID, columnID, timing, key) {
        const { time_arr: timeArr, time_dep: timeDep, connection, note, suppress, service, services, row } = timing

        const data = {
          'key': key,
          'timetable_id': timetableID,
          'column_id': columnID,
          row,
          'data': {
            time_arr: timeArr,
            time_dep: timeDep,
            connection,
            note,
            suppress,
            service,
            services,
          },
        }

        const response = await makeRequest('/timetables/tables/time/add', data)

        if (response.ok) {
          return buildResponse(response)
        } else {
          switch (response.status) {
            case 400:
              return buildError(response, 'Missing fields')
            case 404:
              return buildError(response, 'Cannot find Timetable or Column')
            case 403:
              return buildError(response, 'No permissions to access feature')
            case 409:
              return buildError(response, 'New timing not possible')
            case 410:
              return buildError(response, 'Timetable or Column does not exist')
            default:
              return buildError(response, 'Unknown')
          }
        }
      },
      async edit (timetableID, columnID, timing, key, addRow = false) {
        // eslint-disable-next-line camelcase
        const { time_arr: timeArr, time_dep: timeDep, connection, note, suppress, service, dogleg_start, dogleg_end } = timing

        const data = {
          'key': key,
          'timetable_id': timetableID,
          'column_id': columnID,
          'data': {
            time_arr: timeArr,
            time_dep: timeDep,
            connection,
            note,
            suppress,
            service,
            dogleg_start,
            dogleg_end,
          },
        }

        // Either specify the row to create a new timing, or specify the timing to edit
        if (addRow) {
          data['row'] = timing.row
        } else {
          data['time_id'] = timing.time_id
        }

        // console.log(data,'data frin tunetabke')
        const response = await makeRequest('/timetables/tables/time/edit', data)

        if (response.ok) {
          return buildResponse(response)
        } else {
          switch (response.status) {
            case 400:
              return buildError(response, 'Missing fields')
            case 404:
              return buildError(response, 'Cannot find Table or Column')
            case 403:
              return buildError(response, 'No permissions to access feature')
            case 409:
              return buildError(response, 'Update not possible')
            case 410:
              return buildError(response, 'Table or Column does not exist')
            default:
              return buildError(response, 'Unknown')
          }
        }
      },
      // eslint-disable-next-line camelcase
      async delete (timetable_id, column_id, time_id, key) {
        const response = await makeRequest('/timetables/tables/time/delete', {
          key,
          timetable_id,
          column_id,
          time_id,
        })

        if (response.ok) {
          return buildResponse(response)
        } else {
          switch (response.status) {
            case 400:
              return buildError(response, 'Missing fields')
            case 404:
              return buildError(response, 'Cannot find time')
            default:
              return buildError(response, 'Unknown')
          }
        }
      },
    },
  },

  filters: {
    toc: {
      async list (key) {
        const response = await makeRequest('/timetables/filters/toc/list', { key })

        if (response.ok) {
          return buildResponse(response)
        } else {
          switch (response.status) {
            case 404:
              return buildError(response, 'No filters available')
            default:
              return buildError(response, 'Unknown')
          }
        }
      },
      async create (code, name, key) {
        const response = await makeRequest('/timetables/filters/toc/create', { key, code, name })

        if (response.ok) {
          return buildResponse(response)
        } else {
          switch (response.status) {
            case 412:
              return buildError(response, 'TOC code unknown')
            case 400:
              return buildError(response, 'Missing fields')
            default:
              return buildError(response, 'Unknown')
          }
        }
      },
    },
    headcode: {
      async list (key) {
        const response = await makeRequest('/timetables/filters/headcode/list', { key })

        if (response.ok) {
          return buildResponse(response)
        } else {
          switch (response.status) {
            case 404:
              return buildError(response, 'No filters available')
            default:
              return buildError(response, 'Unknown')
          }
        }
      },
      async create (headcode, name, key) {
        const response = await makeRequest('/timetables/filters/headcode/create', { key, headcode, name })

        if (response.ok) {
          return buildResponse(response)
        } else {
          switch (response.status) {
            case 400:
              return buildError(response, 'Missing fields')
            case 412:
              return buildError(response, 'Headcode unknown')
            default:
              return buildError(response, 'Unknown')
          }
        }
      },
    },
    // eslint-disable-next-line camelcase
    async remove (timetable_id, filter_id, key) {
      const response = await makeRequest('/timetables/tables/filters/remove', { key, timetable_id, filter_id })

      if (response.ok) {
        return buildResponse(response)
      } else {
        switch (response.status) {
          default:
            return buildError(response, 'Unknown')
        }
      }
    },
  },
}
