/* globals require, module */
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import localForage from 'localforage'
import { createStore } from 'store'

import './styles/main.css'
import 'roboto-fontface'

import times2theme from './styles/times2-theme'

import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Helmet from 'components/Helmet'

// Store Initialization
// ------------------------------------
localForage.getItem('times2-state').then(initialState => {
  const store = createStore(initialState !== null ? initialState : undefined)

  store.subscribe(() => {
    // Store a new initial state for next load. This should look like the
    // general initial state, but also including any data you want to cache
    const state = store.getState()
    const { filters, ...clone } = state.reference // eslint-disable-line no-unused-vars

    localForage.setItem('times2-state', {
      reference: clone,
    })
  })

  // Render Setup
  // ------------------------------------
  const MOUNT_NODE = document.getElementById('root')

  let render = () => {
    const PageLayout = require('./layouts/PageLayout/PageLayoutContainer').default

    ReactDOM.render(
      <MuiThemeProvider theme={times2theme}>
        <Helmet />
        <CssBaseline />
        <Provider store={store}>
          <PageLayout />
        </Provider>
      </MuiThemeProvider>,
      MOUNT_NODE
    )
  }

  // Development Tools
  // ------------------------------------
  if (__DEV__) {
    if (module.hot) {
      const renderApp = render
      const renderError = (error) => {
        const RedBox = require('redbox-react').default

        ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
      }

      render = () => {
        try {
          renderApp()
        } catch (e) {
          renderError(e)
          throw e
        }
      }

      // Setup hot module replacement
      module.hot.accept([
        './layouts/PageLayout/PageLayoutContainer',
      ], () =>
        setTimeout(() => {
          ReactDOM.unmountComponentAtNode(MOUNT_NODE)
          render()
        }, 0)
      )
    }
  }

  // Let's Go!
  // ------------------------------------
  if (!__TEST__) render()
})
