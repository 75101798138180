import {
  NOTE_CATERING,
  NOTE_RESERVATION,
  NOTE_CLASS,
} from './constants'

/**
 * A dictionary of TIMESFont symbols, their SVG counterparts, and their text
 * descriptions. Note that these text descriptions might have TOC variations.
 */
export const symbols = {
  '1': {
    icon: '/svgs/gwr-nightnumber-01.svg',
    title: 'First Class accommodation is available',
  },
  '2': {
    icon: '/svgs/gwr-interchange-02.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  '3': {
    icon: '/svgs/gwr-interchange-03.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  '4': {
    icon: '/svgs/gwr-interchange-04.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  '5': {
    icon: '/svgs/gwr-interchange-05.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  '6': {
    icon: '/svgs/gwr-interchange-06.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  '7': {
    icon: '/svgs/gwr-interchange-07.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  '8': {
    icon: '/svgs/gwr-interchange-08.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  '9': {
    icon: '/svgs/gwr-interchange-09.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  '10': {
    icon: '/svgs/gwr-interchange-10.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  '11': {
    icon: '/svgs/gwr-interchange-11.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  '12': {
    icon: '/svgs/gwr-interchange-12.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  '13': {
    icon: '/svgs/gwr-interchange-13.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  '14': {
    icon: '/svgs/gwr-interchange-14.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  '15': {
    icon: '/svgs/gwr-interchange-15.svg',
    title: 'Minimum time, in minutes, you need to allow between connecting services (this is only shown at stations where this differs from the standard 5 minute allowance)',
  },
  'T': {
    icon: '/svgs/gwr-interchange-underground.svg',
    title: 'Interchange with London Underground',
  },
  'W': {
    icon: '/svgs/gwr-interchange-airport.svg',
    title: 'Airport interchange',
  },
  'X': {
    icon: '/svgs/gwr-interchange-nr.svg',
    title: 'Rail station',
  },
  'e': {
    icon: '/svgs/gwr-column-later.svg',
    title: 'Continued in a later column',
  },
  'f': {
    icon: '/svgs/gwr-column-earlier.svg',
    title: 'Continued from an earlier column',
  },
  's': {
    icon: '/svgs/gwr-note-square.svg',
    title: 'Bike reservations are compulsory. First Class accommodation, seat reservations, Wi-Fi and power points are available',
  },
  't': {
    icon: '/svgs/gwr-note-circle.svg',
    title: 'Bike reservations are compulsory',
  },
  '$': {
    icon: '/svgs/gwr-reservations-recommended.svg',
    title: 'Seat reservations are recommended',
  },
  '%': {
    icon: '/svgs/gwr-reservations-compulsory.svg',
    title: 'Seat reservations are compulsory',
  },
  '&': {
    icon: '/svgs/gwr-bus-service.svg',
    title: 'Bus service. We cannot carry heavy luggage, prams, bikes or dogs (except guide dogs) on bus services',
  },
  '(': {
    icon: '/svgs/gwr-catering-pullman.svg',
    title: 'Pullman fine dining available to First Class ticket holders (also Standard Class ticket holders if space is available). Other on-train refreshments are also available for all or part of the journey',
  },
  ')': {
    icon: '/svgs/gwr-plusbus.svg',
    title: 'PLUSBUS available',
  },
  '_': {
    icon: '/svgs/gwr-heritage-railway.svg',
    title: 'Heritage railway connection. Operating days vary',
  },
  '+': {
    icon: '/svgs/gwr-catering-teacup.svg',
    title: 'On-train refreshments are available for all or part of the journey',
  },
  '[': {
    icon: '/svgs/gwr-bikes-not-permitted.svg',
    title: 'Bikes are not permitted on this train',
  },
  ']': {
    icon: '/svgs/gwr-reservations-available.svg',
    title: 'Seat reservations are available',
  },
  ':': {
    icon: '/svgs/gwr-bikes-not-permitted-reading-wokingham.svg',
    title: 'Bikes are not permitted between Reading and Wokingham',
  },
  '@': {
    icon: '/svgs/gwr-sleeper.svg',
    title: 'Overnight Sleeper service with single and twin berth cabins and seating accommodation. Sleeper reservations are compulsory',
  },
  ',': {
    icon: '/svgs/gwr-interchange-ferry.svg',
    title: 'Ferry interchange',
  },
  'H': {
    icon: '/svgs/gwr-heritage-railway.svg',
    title: 'Heritage railway',
  },
  'E': {
    icon: '/svgs/gwr-electrostar.svg',
    title: 'Electrostar train. Wi-Fi and power points are available',
  },
  'w': {
    icon: '/svgs/gwr-wheelchair.svg',
    title: 'Wheelchair only reservations',
  },
}

/**
 * A map between values of notes in the CIF file and their TIMESFont symbol
 * counterparts.
 */
export const symbolTranslations = {
  [NOTE_CATERING]: {
    C: '+',
    F: '(',
    H: '(',
    M: '(',
    P: '(',
    R: '(',
    T: '+',
  },
  [NOTE_RESERVATION]: {
    A: '%',
    E: 't',
    R: '$',
    S: ']',
  },
  [NOTE_CLASS]: {
    B: '1',
    ' ': '1',
    L: '@',
  },
}
