/**
 * API handlers for managing stations, TOCs and TIPLOCs
 */
import { makeRequest, buildResponse, buildError } from 'helpers/requestHandler'

export default {
  tocs: {
    AR: 'Alliance Rail',
    NT: 'Arriva Trains Northern',
    AW: 'Arriva Trains Wales',
    CC: 'c2c',
    CS: 'Caledonian Sleeper',
    CH: 'Chiltern Railway',
    XC: 'CrossCountry',
    EM: 'East Midlands Trains',
    ES: 'Eurostar',
    FC: 'First Capital Connect (defunct)',
    HT: 'First Hull Trains',
    GX: 'Gatwick Express',
    ZZ: 'GB Railfreight',
    GN: 'Govia Thameslink Railway (Great Northern)',
    TL: 'Govia Thameslink Railway (Thameslink)',
    GC: 'Grand Central',
    LN: 'Great North Western Railway',
    GW: 'Great Western Railway',
    LE: 'Greater Anglia',
    HC: 'Heathrow Connect',
    HX: 'Heathrow Express',
    IL: 'Island Lines',
    LS: 'Locomotive Services',
    LM: 'London Midland',
    LO: 'London Overground',
    LT: 'LUL District Line – Wimbledon',
    ME: 'Merseyrail',
    LR: 'Network Rail (On-Track Machines)',
    TW: 'Nexus (Tyne & Wear Metro)',
    NY: 'North Yorkshire Moors Railway',
    SR: 'ScotRail',
    SW: 'South Western Railway',
    SJ: 'South Yorkshire Supertram',
    SE: 'Southeastern',
    SN: 'Southern',
    SP: 'Swanage Railway',
    XR: 'TfL Rail',
    TP: 'TransPennine Express',
    VT: 'Virgin Trains',
    GR: 'Virgin Trains East Coast',
    WR: 'West Coast Railway Co.',
  },

  async list (key) {
    const response = await makeRequest('/timetables/stations', { key })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        case 206:
          return buildError(response, 'No valid stations')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async add (code, tiploc, name, data, key) {
    const response = await makeRequest('/timetables/stations/add', {
      mct: 5,
      ...data,
      code,
      crs: code,
      name,
      detail: name,
      tiplocs: [ tiploc ],
      key,
    })

    if (response.ok) {
      return buildResponse(response)
    } else {
      return buildError(response, 'Unknown')
    }
  },

  async edit (crs, data, key) {
    const response = await makeRequest('/timetables/stations/edit', {
      code: crs,
      ...data,
      key,
    })

    if (response.ok) {
      return buildResponse(response)
    } else {
      return buildError(response, 'Unknown')
    }
  },

  async listValidTocs (key) {
    const response = await makeRequest('/timetables/operators', { key })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Invalid auth key')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async listTiplocs (key) {
    const response = await makeRequest('/timetables/tiplocs', { key })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Invalid auth key')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },
}
