import { applyMiddleware, combineReducers, compose, createStore as createReduxStore } from 'redux'
import thunk from 'redux-thunk'
import authReducer from './auth'
import data from './data'
import notificationReducer from './notifications'
import reference from './reference'

// Create a root reducer, including the async reducers specified
export function makeRootReducer (asyncReducers) {
  return combineReducers({
    notifications: notificationReducer,
    auth: authReducer,
    reference: reference,
    data: data,
    ...asyncReducers,
  })
}

// Load a new reducer and add it to the store
export function injectReducer (store, { key, reducer }) {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

// Create a new store object
export function createStore (initialState = {}) {
  // Use the DevTools compose function if in dev mode, otherwise use the default one
  let composeEnhancers = compose

  if (__DEV__) {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    }
  }

  // Set up store with initial state and Thunk middleware
  const store = createReduxStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(
      applyMiddleware(thunk),
    )
  )

  // Set the static asyncReducers object. When reducer are loaded asynchronously,
  // they'll be logged here for reference.
  store.asyncReducers = {}

  // If in hot-reload mode and one of the reducers is reloaded, reload all the reducers
  if (module.hot) {
    module.hot.accept(['./auth', './data', './file', './notifications', './reference', './table'], () => {
      store.replaceReducer(makeRootReducer(store.asyncReducers))
    })
  }

  return store
}

export default makeRootReducer
