/**
 * Waits for `awaitReducer` to load, then adds it to the Redux store under the
 * supplied `key`, then loads the `ComponentToWrap` with any other props.
 *
 * Because this is a HOC it returns a function acting on the ComponentToWrap.
 *
 * withReducer('cart', import('store/cart'))(CartPreview)
 */
import React from 'react'
import { ReactReduxContext } from 'react-redux'

import { injectReducer } from 'store'

function withReducer (key, awaitReducer) {
  return function (ComponentToWrap) {
    return class WithReducerHOC extends React.Component {
      state = {
        isLoaded: false,
      }

      inject (store) {
        this.started = true

        awaitReducer.then(reducer => {
          injectReducer(store, { key, reducer: reducer.default })
          this.setState({ isLoaded: true })
        })
      }

      render () {
        const { isLoaded } = this.state

        // On first render, inject the reducer
        if (!this.started) {
          return <ReactReduxContext.Consumer>
            {({ store }) => this.inject(store)}
          </ReactReduxContext.Consumer>
        }

        // Show a placeholder until it loads...
        if (!isLoaded) {
          return <div />
        }

        // ...then show the target component
        return <ComponentToWrap {...this.props} />
      }
    }
  }
}

export { withReducer }
