/**
 * API handlers for user account actions, including login and changing password
 */
import { makeRequest, buildResponse, buildError } from 'helpers/requestHandler'

export default class User {
  constructor (email, password) {
    this.email = email
    this.password = password
  }

  // **********************************
  // User Authentication & Registration
  // **********************************

  async login () {
    const response = await makeRequest('/users/login', {
      email: this.email,
      password: this.password,
    })

    if (response.ok) {
      const data = await response.json()

      this.auth = {
        key: data.token,
        validity: data.validity,
      }

      return {
        successful: true,
        data,
      }
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Invalid credentials')
        default:
          return buildError(response, 'Unknown')
      }
    }
  }

  async logout (key) {
    const response = await makeRequest('/users/logout', { key })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Invalid authentication key')
        default:
          return buildError(response, 'Unknown')
      }
    }
  }

  // eslint-disable-next-line camelcase
  async register (email, password, operator_id = null) {
    const response = await makeRequest('/users/register', {
      email,
      password,
      operator_id,
    })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        default:
          return buildError(response, 'Unknown')
      }
    }
  }

  async view (email = null, key) {
    const response = await makeRequest('/users/view', {
      key,
      email: (email || this.email),
    })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        default:
          return buildError(response, 'Unknown')
      }
    }
  }

  async activate (email, key) {
    const response = await makeRequest('/users/activate', { email, key })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        default:
          return buildError(response, 'Unknown')
      }
    }
  }

  async deactivate (email, key) {
    const response = await makeRequest('/users/deactivate', { email, key })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        default:
          return buildError(response, 'Unknown')
      }
    }
  }

  async edit (email, data, key) {
    let body = { key, email }

    for (const key of Object.keys(data)) {
      body[(key === 'email' ? 'new_' + key : key)] = data[key]
    }

    const response = await makeRequest('/users/edit', body)

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        default:
          return buildError(response, 'Unknown')
      }
    }
  }

  async changePassword (currentPassword, newPassword, key) {
    const response = await makeRequest('/users/change-password', {
      key,
      current_password: currentPassword,
      new_password: newPassword,
    })

    if (response.ok) {
      return buildResponse(response)
    } else {
      return buildError(response, 'Unknown')
    }
  }

  async resetPassword (email, code = null, password = null) {
    let response

    if ((code || password) === null) {
      response = await makeRequest('/users/reset', { email })
    } else {
      response = await makeRequest('/users/reset', { email, code, password })
    }

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 404:
          return buildError(response, 'User does not exist')
        default:
          return buildError(response, 'Unknown')
      }
    }
  }
}
