/**
 * A collection of constants used throughout the application. Constants provide
 * explicit description of the number or string being represented.
 *
 * This are often tied directly to values in the back-end, so cannot be changed
 * trivially.
 *
 * Note that all the note type constants are stored as numeric strings, but in
 * the back-end are stored as integers. It would be preferable to use integers
 * on the front-end too, but this would require replacing a lot of historic code
 * converting between numbers and strings.
 */
export const NOTE_CHANGE = '0'
export const NOTE_REQUEST = '10'
export const NOTE_ARRIVES = '11'
export const NOTE_PICKS_UP = '12'
export const NOTE_SETS_DOWN = '13'
export const NOTE_DEPARTS = '14'
export const NOTE_PREVIOUS = '15'
export const NOTE_VARIANCE = '30'
export const NOTE_CUSTOM = '90'

export const NOTE_CLASS = '40'
export const NOTE_CATERING = '0'
export const NOTE_RESERVATION = '10'
export const NOTE_DATES_OPERATION = '20'
export const NOTE_DAYS_DOES_NOT_RUN = '21'
export const NOTE_DAYS_DOES_RUN = '22'
export const NOTE_ALSO_CALLS = '30'
export const NOTE_ORIGIN = '50'
export const NOTE_DESTINATION = '51'

export const FACILITIES_NOTES = [
  NOTE_CLASS,
  NOTE_RESERVATION,
  NOTE_CATERING,
]

export const GLOBAL_NOTES = {
  x: NOTE_REQUEST,
  a: NOTE_ARRIVES,
  p: NOTE_PREVIOUS,
  u: NOTE_PICKS_UP,
  s: NOTE_SETS_DOWN,
  d: NOTE_DEPARTS,
}

export const CUSTOM_NOTE_TYPES = [
  NOTE_DATES_OPERATION,
  NOTE_ALSO_CALLS,
  NOTE_CUSTOM,
]

export const STOP_TYPE_DEPARTURE = 1
export const STOP_TYPE_ARRIVAL = 2
