/* globals AppVersion */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { NavLink, Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import Times2Logo from 'styles/logo/times2-logo.svg'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import InfoIcon from '@material-ui/icons/Info'
import HomeIcon from '@material-ui/icons/Home'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import NewReleasesIcon from '@material-ui/icons/NewReleasesOutlined'
import RefreshIcon from '@material-ui/icons/Refresh'

const styleSheet = ({ palette, spacing, typography }) => ({
  header: {
    display: 'flex',
  },
  toolBar: {
    width: '100%',
    padding: 0,
  },
  logo: {
    width: 64,
    height: 64,
  },
  flexItem: {
    flex: 'auto',
  },
  tabContainer: {
    display: 'flex',
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing.unit * 3,
    paddingRight: spacing.unit * 3,
    color: 'inherit',
    textTransform: 'uppercase',
    textDecoration: 'none',
    opacity: 0.6,
  },
  tabLabel: {
    color: 'inherit',
    fontWeight: typography.fontWeightMedium,
    fontSize: typography.pxToRem(13),
  },
  tabSelected: {
    opacity: 1,
    color: palette.primary.main,
    '& > $tabLabel': {
      fontWeight: 'bold',
    },
  },
  tabSeparator: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    opacity: 0.6,
  },
  colorPrimary: {
    backgroundColor: palette.grey.main,
    color: palette.grey.contrastText,
  },
})

class AppToolbar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    onRefreshData: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isAdmin: false,
  }

  state = {
    anchorEl: null,
    isOpen: false,
  }

  handleMenu = event => {
    this.setState({
      anchorEl: event.currentTarget,
      isOpen: true,
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
      isOpen: false,
    })
  }

  handleLogout = () => {
    this.handleClose()
    this.props.onLogout({
      type: 'LOGOUT',
    })
  }

  render () {
    const { classes, match, isAdmin, onRefreshData } = this.props
    const { anchorEl, isOpen } = this.state

    let timetableId = match.params.id

    // If it's a list of timetables with just one entry, treat that like only having one
    if (match.params.ids && !match.params.ids.includes(',')) {
      timetableId = match.params.ids.split(',')[0]
    }

    const tabSeparator = <Typography className={classes.tabSeparator}>&raquo;</Typography>

    return (
      <AppBar elevation={0} position="static" classes={{
        colorPrimary: classes.colorPrimary,
      }}>
        <div className={classes.header}>
          <img alt="" role="presentation" src={Times2Logo} className={classes.logo} />
          <Toolbar className={classes.toolBar}>
            <div className={`${classes.tabContainer} ${classes.flexItem}`}>
              <NavLink exact to="/" className={classes.tab} activeClassName={classes.tabSelected}>
                <HomeIcon />
              </NavLink>
              { timetableId &&
                <React.Fragment>
                  {tabSeparator}
                  <NavLink exact to={`/edit/${timetableId}/extract`} className={classes.tab} activeClassName={classes.tabSelected}>
                    <Typography className={classes.tabLabel}>Extract</Typography>
                  </NavLink>
                  {tabSeparator}
                  <NavLink exact to={`/edit/${timetableId}/stations`} className={classes.tab} activeClassName={classes.tabSelected}>
                    <Typography className={classes.tabLabel}>Stations</Typography>
                  </NavLink>
                  {tabSeparator}
                  <NavLink exact to={`/edit/${timetableId}`} className={classes.tab} activeClassName={classes.tabSelected}>
                    <Typography className={classes.tabLabel}>Edit</Typography>
                  </NavLink>
                </React.Fragment>
              }
            </div>

            <div className={classes.tabContainer}>
              { timetableId
                ? <NavLink exact to={`/output/${timetableId}`} className={classes.tab} activeClassName={classes.tabSelected}>
                  <Typography className={classes.tabLabel}>Output</Typography>
                </NavLink>
                : <NavLink exact to="/output" className={classes.tab} activeClassName={classes.tabSelected}>
                  <Typography className={classes.tabLabel}>Output</Typography>
                </NavLink>
              }
            </div>
            <IconButton
              aria-owns={isOpen ? 'menu-appbar' : null}
              aria-haspopup="true"
              ref={ref => { this.anchorEl = ref }}
              onClick={this.handleMenu}
              color="inherit"
              className={classes.accountButton}
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={isOpen}
              onClose={this.handleClose}
            >
              <MenuItem>
                <ListItemIcon><InfoIcon /></ListItemIcon>
                <ListItemText inset primary={AppVersion} />
              </MenuItem>
              { isAdmin &&
                // Has to be array since keyboard navigation requires that
                // children are not fragments.
                [
                  <Divider key="divider" />,
                  <MenuItem key="admin-menu" component={Link} to="/admin">
                    <ListItemIcon><NewReleasesIcon /></ListItemIcon>
                    <ListItemText inset primary="Admin panel" />
                  </MenuItem>,
                ]
              }
              <Divider />
              <MenuItem onClick={onRefreshData}>
                <ListItemIcon><RefreshIcon /></ListItemIcon>
                <ListItemText inset primary="Refresh reference data" />
              </MenuItem>
              <MenuItem component={Link} to="/user/change-password">
                <ListItemIcon><LockOutlinedIcon /></ListItemIcon>
                <ListItemText inset primary="Change password" />
              </MenuItem>
              <MenuItem onClick={this.handleLogout}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText inset primary="Log out" />
              </MenuItem>
            </Menu>
          </Toolbar>
        </div>
      </AppBar>
    )
  }
}

export default withStyles(styleSheet)(AppToolbar)
