/**
 * Defines *global* and *reserved* notes.
 *
 * Global notes are consistently used by all TOCs and timetables. For example,
 * every timetable uses 'a' to mean "Arrival time". These also include notes
 * that we generate based on the CIF file (e.g. class notes are always 'B' or
 * 'L')
 *
 * Reserved notes are similar, but TOC-specific. These may include common
 * phrases (like GWR's 'b' for "By bus") or additional train facilities (like
 * the symbol 'E' for electrostar trains)
 *
 * We define all these notes up-front so that they are always available when a
 * user looks at a list of "timetable footnotes". If any of these placeholder
 * notes are used, they're created on the backend for persistence.
 */

import {
  NOTE_REQUEST,
  NOTE_ARRIVES,
  NOTE_PICKS_UP,
  NOTE_SETS_DOWN,
  NOTE_DEPARTS,
  NOTE_CUSTOM,
  NOTE_CLASS,
  NOTE_CATERING,
  NOTE_PREVIOUS,
  NOTE_RESERVATION,
} from 'helpers/constants'

const globalNotes = [
  // Timing notes
  { note_id: '_new-NOTE_REQUEST', note: 'x', note_type: { var: 'location', type: NOTE_REQUEST, text: 'Stops on request. Please tell the Conductor if you wish to leave. Please signal to the Driver if you wish to board' } },
  { note_id: '_new-NOTE_ARRIVES', note: 'a', note_type: { var: 'location', type: NOTE_ARRIVES, text: 'Arrival time' } },
  { note_id: '_new-NOTE_PICKS_UP', note: 'u', note_type: { var: 'location', type: NOTE_PICKS_UP, text: 'Stops to pick up passengers only' } },
  { note_id: '_new-NOTE_PREVIOUS_NIGHT', note: 'p', note_type: { var: 'location', type: NOTE_PREVIOUS, text: 'Previous night' } },
  { note_id: '_new-NOTE_SETS_DOWN', note: 's', note_type: { var: 'location', type: NOTE_SETS_DOWN, text: 'Stops to set down passengers only' } },
  { note_id: '_new-NOTE_DEPARTS', note: 'd', note_type: { var: 'location', type: NOTE_DEPARTS, text: 'Departure time' } },
  // Column notes
  { note_id: '_new-NOTE_CLASS', note: 'B', note_type: { var: 'column', type: NOTE_CLASS } },
  { note_id: '_new-NOTE_CLASS', note: 'L', note_type: { var: 'column', type: NOTE_CLASS } },
  { note_id: '_new-NOTE_RESERVATION_A', note: 'A', note_type: { var: 'column', type: NOTE_RESERVATION } },
  { note_id: '_new-NOTE_RESERVATION_E', note: 'E', note_type: { var: 'column', type: NOTE_RESERVATION } },
  { note_id: '_new-NOTE_RESERVATION_R', note: 'R', note_type: { var: 'column', type: NOTE_RESERVATION } },
  { note_id: '_new-NOTE_RESERVATION_S', note: 'S', note_type: { var: 'column', type: NOTE_RESERVATION } },
  { note_id: '_new-NOTE_CATERING_C', note: 'C', note_type: { var: 'column', type: NOTE_CATERING } },
  { note_id: '_new-NOTE_CATERING_F', note: 'F', note_type: { var: 'column', type: NOTE_CATERING } },
  { note_id: '_new-NOTE_CATERING_H', note: 'H', note_type: { var: 'column', type: NOTE_CATERING } },
  { note_id: '_new-NOTE_CATERING_M', note: 'M', note_type: { var: 'column', type: NOTE_CATERING } },
  { note_id: '_new-NOTE_CATERING_P', note: 'P', note_type: { var: 'column', type: NOTE_CATERING } },
  { note_id: '_new-NOTE_CATERING_R', note: 'R', note_type: { var: 'column', type: NOTE_CATERING } },
  { note_id: '_new-NOTE_CATERING_T', note: 'T', note_type: { var: 'column', type: NOTE_CATERING } },
]

const reservedNotes = {
  GW: {
    location: {
      b: 'By bus',
      ae: 'Passengers may arrive earlier by travelling on an later train',
      uf: 'On Fridays, only stops to pick up passengers',
      pa: 'Previous night. Arrival time',
      pb: 'Previous night. By bus',
      pd: 'Previous night. Departure time',
      ps: 'Previous night. Stops to set down passengers only',
      pu: 'Previous night. Stops to pick up passengers only',
      px: 'Previous night. Stops on request only',
    },
    column: {
      '#s': 'Bike reservations are compulsory. First Class accommodation, seat reservations, Wi-Fi and power points are available',
      '#E': 'Electrostar train. Wi-Fi and power points are available',
      '#[': 'Bikes are not permitted on this train',
      '#:': 'Bikes are not permitted between Reading and Wokingham',
      '#&': 'Bus service. We cannot carry heavy luggage, prams, bikes or dogs (except guide dogs) on bus services',
      '#w': 'Wheelchair only reservations',
    },
  },
}

export function getReservedNotes (operator) {
  const operatorNotes = [];

  ['location', 'column'].forEach(type => {
    Object.keys(reservedNotes[operator][type]).forEach(key => {
      operatorNotes.push({
        note_id: `_new-reserved-${operator.toLowerCase()}-${key}`,
        note_type: {
          var: type,
          type: NOTE_CUSTOM,
        },
        text: reservedNotes[operator][type][key],
        note: key,
        isReserved: true,
      })
    })
  })

  return [
    ...globalNotes,
    ...operatorNotes,
  ].map(note => ({
    ...note,
    isPlaceholder: true,
    note_type: {
      ...note.note_type,
      type: parseInt(note.note_type.type, 10),
    },
  }))
}
