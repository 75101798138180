import React from 'react'
import PropTypes from 'prop-types'

import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const propTypes = {
  notification: PropTypes.string.isRequired,
  onDismissNotification: PropTypes.func.isRequired,
}

const Notification = ({ notification, onDismissNotification }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    open={!!notification}
    autoHideDuration={3000}
    onClose={onDismissNotification}
    message={<span id="message-id">{notification}</span>}
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={onDismissNotification}
      >
        <CloseIcon />
      </IconButton>,
    ]}
  />
)

Notification.propTypes = propTypes

export default Notification
