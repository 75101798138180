/**
 * Provides the frame for the front-end application and implements all routing.
 * Routes are loaded using React.lazy to enable code-splitting and minimise the
 * bundle size sent to browsers.
 */
import React, { lazy, Suspense, Component } from 'react'
import PropTypes from 'prop-types'
import browserHistory from 'helpers/history'
import { Router, Redirect, Route, Switch } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import AppToolbar from 'containers/AppToolbar'
import ErrorPage from 'components/ErrorPage'
import FullPageSpinner from 'components/FullPageSpinner'
import LoginContainer from 'routes/Login/containers/LoginContainer'
import CurrentNotification from 'containers/CurrentNotification'

const LandingPage = lazy(() => import('routes/Home/containers/LandingPage'))
const ResetPasswordContainer = lazy(() => import('routes/Login/containers/ResetPasswordContainer'))
const ChangePasswordContainer = lazy(() => import('routes/Login/containers/ChangePasswordContainer'))
const NewFileContainer = lazy(() => import('routes/Home/containers/NewFileContainer'))
const EditorTimetable = lazy(() => import('routes/Edit/containers/EditorTimetable'))
const EditorStationBank = lazy(() => import('routes/Edit/containers/EditorStationBank'))
const TimetableFrame = lazy(() => import('routes/Table/containers/TimetableFrame'))
const MultiPreview = lazy(() => import('routes/Output/containers/MultiPreview'))
const TableSelector = lazy(() => import('routes/Output/containers/TableSelector'))
const AdminPanel = lazy(() => import('routes/Admin/containers/AdminPanel'))

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    filter: '',
    transition: 'filter 1s',
  },
  rootBlurred: {
    filter: 'blur(10px)',
  },
  container: {
    flex: 1,
    overflow: 'auto',
  },
})

function AwaitComponent (Component) {
  return function awaitedComponent (props) {
    return (
      <Suspense fallback={<FullPageSpinner />}>
        <Component {...props} />
      </Suspense>
    )
  }
}

class PageLayout extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isLoginModalOpen: PropTypes.bool.isRequired,
    onTestLogin: PropTypes.func.isRequired,
  }

  state = {
    ready: false,
  }

  componentDidMount () {
    if (window.location.pathname.includes('/user/reset-password')) {
      this.setState({
        ready: true,
      })
    } else {
      this.props.onTestLogin().then(() => {
        this.setState({
          ready: true,
        })
      })
    }
  }

  render () {
    const { isLoginModalOpen, classes } = this.props
    const { ready } = this.state

    if (ready) {
      return (
        <div className={`${classes.root} ${isLoginModalOpen ? classes.rootBlurred : ''}`}>
          <Router history={browserHistory}>
            <>
              {/*
                This array syntax throws an error in dev tools but *is*
                supported by React Router. Once React Router is upgraded to
                4.4.x, the error will disappear.
              */}
              <Route
                path={[
                  '/edit/:id',
                  '/output/:ids',
                  '*',
                ]}
                component={AppToolbar}
              />
              <div className={classes.container}>
                <Switch>
                  <Redirect path="home" to="/" />
                  <Route exact path="/" component={AwaitComponent(LandingPage)} />
                  <Route path="/user/reset-password/:code" component={AwaitComponent(ResetPasswordContainer)} />
                  <Route path="/user/change-password" component={AwaitComponent(ChangePasswordContainer)} />
                  <Route path="/new" component={AwaitComponent(NewFileContainer)} />
                  <Route path="/edit/:timetableId/extract" component={AwaitComponent(EditorTimetable)} />
                  <Route path="/edit/:timetableId/stations" component={AwaitComponent(EditorStationBank)} />
                  <Route path="/edit/:timetableId" component={AwaitComponent(TimetableFrame)} />
                  <Route path="/output/:timetableIds" component={AwaitComponent(MultiPreview)} />
                  <Route path="/output" component={AwaitComponent(TableSelector)} />
                  <Route path="/admin" component={AwaitComponent(AdminPanel)} />
                  <Route path="/" component={AwaitComponent(ErrorPage)} />
                </Switch>
              </div>
            </>
          </Router>
          <LoginContainer />
          <CurrentNotification />
        </div>
      )
    } else {
      return (<div />)
    }
  }
}

export default withStyles(styles)(PageLayout)
