/**
 * API handlers for managing station banks
 */
import { makeRequest, buildResponse, buildError } from 'helpers/requestHandler'

export default {
  async view (id = null, key) {
    const response = await makeRequest('/timetables/bank/view', { id, key })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        case 404:
          return buildError(response, 'Station Bank does not exist')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async create (data, key) {
    if (!data.name) {
      const stationFrom = data.locations[0]
      const stationTo = data.locations[data.locations.length - 1]
      data.name = `${stationFrom.name} to ${stationTo.name}`
    }

    const response = await makeRequest('/timetables/bank/create', { key, ...data })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        case 409:
          return buildError(response, 'The requested name already exists')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async list (all = false, key) {
    const response = await makeRequest('/timetables/bank/list', { all, key })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        case 206:
          return buildError(response, 'No valid tables')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async edit (id, data, key) {
    const response = await makeRequest('/timetables/bank/edit', { key, id, ...data })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        case 409:
          return buildError(response, 'The requested name already exists')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  async delete (id, key) {
    const response = await makeRequest('/timetables/bank/delete', { key, id })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 403:
          return buildError(response, 'Not enough permissions to perform operation')
        case 409:
          return buildError(response, 'The requested name already exists')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  // eslint-disable-next-line camelcase
  async reverse (base_id, name, key) {
    const response = await makeRequest('/timetables/bank/reverse', { key, base_id, name })

    if (response.ok) {
      return buildResponse(response)
    } else {
      switch (response.status) {
        case 400:
          return buildError(response, 'Cannot edit this bank as not staff/not the bank creator')
        case 403:
          return buildError(response, 'User not logged in or not authorised to create new content')
        case 404:
          return buildError(response, 'Station bank does nto exist')
        case 409:
          return buildError(response, 'Requested name already exists')
        default:
          return buildError(response, 'Unknown')
      }
    }
  },

  filters: {
    callingpoint: {
      async list (bank, key) {
        const response = await makeRequest('/timetables/bank/filters/list', {
          key,
          bank_id: typeof bank === 'object' ? bank.id : bank,
        })

        if (response.ok) {
          return buildResponse(response)
        } else {
          switch (response.status) {
            case 404:
              return buildError(response, 'No filters available')
            case 400:
              return buildError(response, 'Missing fields')
            default:
              return buildError(response, 'Unknown')
          }
        }
      },
      async view (bank, filter, key) {
        const response = await makeRequest('/timetables/bank/filters/view', {
          key,
          bank_id: bank.id,
          filter_id: filter,
        })

        if (response.ok) {
          return buildResponse(response)
        } else {
          switch (response.status) {
            case 404:
              return buildError(response, 'Filter not available')
            case 400:
              return buildError(response, 'Missing fields')
            default:
              return buildError(response, 'Unknown')
          }
        }
      },
      async create (bank, locations, name, key) {
        const response = await makeRequest('/timetables/bank/filters/create', {
          key,
          bank_id: bank.id,
          locations,
          name,
        })

        if (response.ok) {
          return buildResponse(response)
        } else {
          switch (response.status) {
            case 400:
              return buildError(response, 'Locations missing')
            default:
              return buildError(response, 'Unknown')
          }
        }
      },
      // eslint-disable-next-line camelcase
      async delete (bank_id, filter_id, key) {
        const response = await makeRequest('/timetables/bank/filters/delete', {
          key,
          bank_id,
          filter_id,
        })

        if (response.ok) {
          return buildResponse(response)
        } else {
          switch (response.status) {
            default:
              return buildError(response, 'Unknown')
          }
        }
      },
    },
  },
}
