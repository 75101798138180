/**
 * Sometimes the data we get from the API isn't in a great format for the Times2
 * front-end. We account for some of this inside Redux stores and components,
 * but it can be very processing-heavy if it has to be performed multiple times.
 *
 * This file provides functions which can be called when a timetable's object is
 * loaded from the API *before* it is put in Redux. This means that, for the
 * entirety of the lifecycle of that data, it is in a suitable and usable format.
 *
 * Some of these funcitions are also applied as data is changed by users. For
 * example, `calculateInvalidTimes` is reapplied every time a user changes a
 * time to help identify mistakes.
 */
import { NOTE_DATES_OPERATION, STOP_TYPE_DEPARTURE } from 'helpers/constants'
import { clampDate } from 'helpers/dates'
import { getReservedNotes } from 'helpers/reservedNotes'
import { noteTypeHandler } from 'helpers/notes'

/**
 * Given a timetable definition, identify any additional notes which need to be
 * applied and the columns they should be applied to. Returns note/column
 * identifiers to avoid mutating state.
 *
 * Adds NOTE_DATES_OPERATION notes when the service a column is using doesn't
 * match the dates of operation of the timetable.
 */
export function calculateNotes (data) {
  const timetableFrom = clampDate(new Date(data.date_from), data.days_run)
  const timetableTo = clampDate(new Date(data.date_to), data.days_run, { backwards: true })

  const notes = []

  data.columns.forEach(column => {
    const service = column.services ? data.services.find(s => s.id === column.services[0].id) : null
    if (!service) return

    // Add dates of operation notes
    const periods = service.dates
      .map(range => {
        const rangeFrom = new Date(range.valid_from)
        const rangeTo = new Date(range.valid_to)

        const daysRun = {
          mon: range.runs_mon,
          tue: range.runs_tue,
          wed: range.runs_wed,
          thu: range.runs_thu,
          fri: range.runs_fri,
          sat: range.runs_sat,
          sun: range.runs_sun,
        }
        const timetableFromClamped = clampDate(timetableFrom, daysRun)
        const timetableToClamped = clampDate(timetableTo, daysRun, { backwards: true })

        if (rangeFrom > timetableFromClamped || rangeTo < timetableToClamped) {
          const dateFrom = rangeFrom > timetableFromClamped ? rangeFrom : timetableFromClamped
          const dateTo = rangeTo < timetableToClamped ? rangeTo : timetableToClamped
          return [dateFrom, dateTo]
        } else {
          return null
        }
      })
      .filter(x => !!x)
      .sort((a, b) => {
        if (a[0] === b[0]) {
          return a[1] - b[1]
        } else {
          return a[0] - b[0]
        }
      })

    if (periods.length) {
      const key = periods.reduce((acc, period) => acc.concat(period[0]).concat(period[1]), '')
      const findNote = notes.find(n => n.key === key)

      if (findNote) {
        // If we've already created a note like that, assign it to this column
        findNote.columnIds.push(column.column_id)
      } else {
        // If no similar note exists, create it
        // DANGER
        const noteId = column.column_id

        notes.push({
          key,
          note: {
            note_id: noteId,
            note: '',
            note_type: {
              var: 'column',
              type: NOTE_DATES_OPERATION,
              text: 'Operates',
            },
            periods,
          },
          columnIds: [column.column_id],
        })
      }
    }
  })

  return notes
}

/**
 * Given an array of timings, identify and mark which ones are out of *vertical*
 * time order. These timings will be highlighted in the editor to indicate that
 * they may need to be corrected.
 *
 * Returns a copy of the timings, with `isInvalid` flags added to any necessary.
 */
export function calculateInvalidTimes (timings, locations) {
  // Construct an array of bold times (as digits) and the indices
  const boldTimes = timings
    .reduce((accumulator, timing, index) => {
      if (timing.connection || typeof timing.time_id === 'undefined') return accumulator

      const timeProp = locations[timing.row].display.code === STOP_TYPE_DEPARTURE ? 'time_dep' : 'time_arr'
      const otherProp = timeProp === 'time_dep' ? 'time_arr' : 'time_dep'
      const time = timing[timeProp] || timing[otherProp] || null

      accumulator.push({
        index,
        time: time ? parseInt(time.replace(':', ''), 10) : null,
        station: locations[timing.row].station,
      })

      return accumulator
    }, [])

  // console.log(timings)
  // Check through each timing. If the time is before the previous one
  // then mark them both as invalid.
  const invalidTimings = []
  boldTimes.forEach((timing, i, arr) => {
    if (i === 0) return true
    const prevTiming = arr[i - 1]
    // Timings must be sequential, but can be equal if at the same station
    if (timing.station === prevTiming.station ? timing.time < prevTiming.time : timing.time <= prevTiming.time) {
      // If we've skipped over midnight, assume this is a false positive
      if (timing.time < 200 && prevTiming.time > 2200) return true

      invalidTimings.push(prevTiming.index)
      invalidTimings.push(timing.index)
    }
  })

  return timings.map((timing, index) => ({
    ...timing,
    isInvalid: invalidTimings.includes(index),
  }))
}

// DOG LEGS
/**
 * Given an array of columns, identify and mark which timings are out of
 * horizontal time order. This behaviour indicates where sorting might have
 * failed or dog legs should be put in.
 */
export function calculateOvertakenTrains (columns, locations) {
  // Turn the timetable into a set of rows
  const rows = locations.map((_, rowIndex) => {
    return columns.map(col => {
      const timing = col.timings.find(timing => timing.row === rowIndex)

      return {
        id: `${col.column_id}:${rowIndex}`,
        val: timing ? parseInt(timing.time.replace(/:/g, '')) : null,
      }
    }).filter(x => x.val !== null)
  })
  // console.log(rows[1])
  // Go through each row to identify timings which are out of horizontal order
  const overtakenTimings = []
  const slowerTrains = []
  // let dogStart = []
  // let dogEnd = []

  rows.forEach(row => {
    row.forEach(({ id, val }, index) => {
      if (index === row.length - 1) return
      const nextTiming = row[index + 1]
      const nextVal = nextTiming.val

      if (nextVal < val && !(val > 2000 && nextVal < 100)) {
        overtakenTimings.push(nextTiming.id)
        // Below are the train times that are overtaking!
        slowerTrains.push(row[index].id)
      }
      /*
       Code for highlighting the time slots above and below is commented out
      because those cells don't have any times in them and because of that
      there's not much we can do with it apart from display it as an empty cell
      also a few console log statements,
      */

      // if (nextVal === val) {
      //   // console.log(row[index +1][index + 1], 'this is plus one')
      //   // console.log(nextVal,'', val)
      //   // console.log(nextTiming.val)
      //   // console.log(row[index].val)
      //   // // let num = row[index].id.slice(0,-1)
      //   // console.log(row[index].id.slice(37) + 1)
      //   // console.log(row[index].id)
      //   // console.log(nextTiming.id)

      //   // console.log(nextTiming.id)
      //   // let baseDL = nextTiming.id
      //   // baseDL = nextTiming.id.split(':')
      //   // let oneDown = baseDL[1]
      //   // oneDown--
      //   // let oneDownTime = baseDL[0] + ':' + oneDown
      //   // console.log(oneDownTime,'is down')
      //   // Swapping dog start and End around
      //   dogStart.push(row[index].id)
      //   // dogStart.push(oneDownTime)

      //   // console.log(nextTiming.id)
      //   // let baseDLI = row[index].id
      //   // baseDLI = row[index].id.split(':')
      //   // let oneUp = baseDLI[1]
      //   // oneUp++
      //   // let oneUpTime = baseDLI[0] + ':' + oneUp
      //   // console.log(oneUpTime, 'is up!!!')

      //   dogEnd.push(nextTiming.id)
      // }
    })
  })
  // Update each timing to set it's `isOvertaken` flag
  return columns.map((column) => ({
    ...column,
    timings: column.timings.map((timing) => ({
      ...timing,
      isOvertaken: overtakenTimings.includes(`${column.column_id}:${timing.row}`),
      slowerTrain: slowerTrains.includes(`${column.column_id}:${timing.row}`),
      // dogStart: dogStart.includes(`${column.column_id}:${timing.row}`),
      // dogEnd: dogEnd.includes(`${column.column_id}:${timing.row}`),
    })),
  })
  )
}

/**
 * Given some timetable data loaded from the API, put it into a state that is
 * suitable for the front-end. This uses the above functions and a few other
 * behaviours:
 * - Removes all preset dates of operation notes (because these are added by
 *   `calculateNotes`)
 * - Merges in all global and reserved notes so they're accessible for users. If
 *   the table already has a note matching a global/reserved definition, it is
 *   merged instead of being duplicated.
 */
export default function prepareTableData (data) {
  // Remove existing dates of operation notes, since they're no longer applicable
  data.notes.column = data.notes.column.filter(note => {
    if (`${note.note_type.type}` === NOTE_DATES_OPERATION) {
      // Remove the note from all assign columns
      if (data.columns) {
        data.columns.forEach(col => {
          col.notes = col.notes.filter(id => id !== note.note_id)
        })
      }
      // Filter out the note
      return false
    } else {
      return true
    }
  })

  const notes = {
    column: [],
    location: [],
  }
  // Add global and reserved notes
  getReservedNotes('GW').forEach(reservedNote => {
    const attachment = reservedNote.note_type.var
    const existingNote = data.notes[attachment] && data.notes[attachment].find(x => noteTypeHandler(x) === noteTypeHandler(reservedNote))
    if (existingNote) {
      // If a note is already in use (e.g. an "Arrival time" note has been
      // generated already), mark that as the global/reserved
      existingNote.note = reservedNote.note
      existingNote.isReserved = reservedNote.isReserved
    } else {
      // Otherwise, add the placeholder to the pile
      notes[attachment].push(reservedNote)
    }
  })

  data.notes.location = notes.location.concat(data.notes.location)
  data.notes.column = notes.column.concat(data.notes.column)

  if (data.columns) {
    // Identify invalid times (e.g. if its departure time is before the
    // last stop). Connections are ignored because it's fine for them to
    // diverge from the main train timings.
    data.columns.forEach(column => {
      if (column.timings) {
        column.timings = calculateInvalidTimes(column.timings, data.bank.locations)
      }
    })

    // [WIP]: Calculate overtaken trains
    if (data.bank.locations) {
      data.columns = calculateOvertakenTrains(data.columns, data.bank.locations)
    }
    // console.log(data.columns)

    // Add any additional notes to the table
    if (data.date_from && data.date_to && data.days_run) {
      calculateNotes(data).forEach(({ note, columnIds }) => {
        data.notes[note.note_type.var].push(note)
        columnIds.forEach(columnId => {
          const column = data.columns.find(c => c.column_id === columnId)
          column.notes.unshift(note.note_id)
        })
      })
    }
  }

  return data
}
