import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import TrainIcon from '@material-ui/icons/Train'

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
  },
})

const propTypes = {
  classes: PropTypes.object.isRequired,
}

const ErrorPage = ({ classes }) => (
  <main className={classes.root}>
    <TrainIcon color="primary" style={{ fontSize: '16rem' }} />
    <Typography variant="h2" gutterBottom>Page not found</Typography>
    <Typography paragraph>The requested page could not be found. It may have been moved or deleted.</Typography>

    <Button component={Link} to="/" variant="contained" color="primary">
      Return to home page
    </Button>
  </main>
)

ErrorPage.propTypes = propTypes

export default withStyles(styles)(ErrorPage)
