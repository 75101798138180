/**
 * This store simply queues and dismisses any application-wide notifications
 */

export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

export function pushNotification (message) {
  return (dispatch) => {
    dispatch({
      type: PUSH_NOTIFICATION,
      payload: message,
    })
  }
}

export function dismissNotification () {
  return (dispatch) => {
    dispatch({
      type: REMOVE_NOTIFICATION,
    })
  }
}

const ACTION_HANDLERS = {
  [PUSH_NOTIFICATION]: (state, action) => {
    if (action.payload === state[state.length - 1]) {
      return state
    } else {
      return [...state, action.payload]
    }
  },
  [REMOVE_NOTIFICATION]: (state) => state.slice(1),
}

export default function notificationReducer (state = [], action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
