import stationBankThunk from 'thunks/classes/StationBank'
import timetableThunk from 'thunks/classes/Timetable'
import prepareTableData from 'helpers/prepareTableData'

/**
 * This store is for handling station bank and timetable information. It is used
 * by multiple components that control timetables.
 */

export const STATION_BANK_SET_LOCATIONS = 'STATION_BANK_SET_LOCATIONS'
export const STATION_BANK_SET_SORT = 'STATION_BANK_SET_SORT'
export const BANK_LOAD = 'BANK_LOAD'
export const BANK_UPDATE = 'BANK_UPDATE'
export const TIMETABLE_LOAD = 'TIMETABLE_LOAD'
export const TIMETABLE_UPDATE = 'TIMETABLE_UPDATE'

export function loadTimetable (id) {
  return async (dispatch, getState) => {
    const timetable = await timetableThunk.checkStatus(id, getState().auth.user.token)

    dispatch({
      type: TIMETABLE_LOAD,
      timetable: timetable.data,
    })
  }
}

export function loadTimetableDetails (id) {
  return async (dispatch, getState) => {
    const timetable = await timetableThunk.view(id, getState().auth.user.token)

    dispatch({
      type: TIMETABLE_LOAD,
      timetable: prepareTableData(timetable.data),
    })
  }
}

export function generateTimetable (id) {
  return async (dispatch, getState) => {
    await timetableThunk.regenerate(id, getState().auth.user.token)

    dispatch(loadTimetable(id))
  }
}

export function loadTimetables () {
  return async (dispatch, getState) => {
    const { data } = await timetableThunk.list(getState().auth.user.token)

    data.forEach(timetable => {
      dispatch({
        type: TIMETABLE_LOAD,
        timetable: timetable,
      })
    })
  }
}

export function loadBanks (options = { all: false }) {
  return async (dispatch, getState) => {
    const banks = await stationBankThunk.list(options.all, getState().auth.user.token)

    banks.data.forEach(bank => {
      if (!options.all) bank.is_template = true
      dispatch({
        type: BANK_LOAD,
        bank,
      })
    })
  }
}

export function loadBank (id) {
  return async (dispatch, getState) => {
    const { data } = await stationBankThunk.view(id, getState().auth.user.token)

    return data
  }
}

export function createBank (data) {
  return async (dispatch, getState) => {
    data.locations.forEach(location => {
      location.display = location.display.code
    })

    const result = await stationBankThunk.create(data, getState().auth.user.token)

    dispatch({
      type: BANK_LOAD,
      bank: {
        ...data,
        id: result.data.id,
      },
    })
  }
}

export function cloneBank (bankId, name, options = { isTemplate: false }) {
  return async (dispatch, getState) => {
    const result = await stationBankThunk.view(bankId, getState().auth.user.token)

    dispatch(createBank({
      name,
      locations: result.data.locations,
      is_template: options.isTemplate,
    }))
  }
}

export function reverseBank (bankId, name, options = { isTemplate: false }) {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.user.token

    // Create a new bank, reversed
    const result = await stationBankThunk.reverse(bankId, name, accessToken)

    // If the bank is a template, mark it so
    if (options.isTemplate) {
      await dispatch(updateBank(result.data.id, { is_template: true }))
    }

    // Get the new bank's details
    const { data: newBank } = await stationBankThunk.view(result.data.id, accessToken)

    dispatch({
      type: BANK_LOAD,
      bank: newBank,
    })
  }
}

export function updateBank (bankId, data) {
  return async (dispatch, getState) => {
    await stationBankThunk.edit(bankId, data, getState().auth.user.token)

    dispatch({
      type: BANK_UPDATE,
      bankId,
      data,
    })
  }
}

export function updateBankLocations (bankId, locations) {
  return async (dispatch, getState) => {
    const locationsToSave = locations.map(l => ({ ...l, display: l.display.code }))

    await stationBankThunk.edit(bankId, { locations: locationsToSave }, getState().auth.user.token)

    dispatch({
      type: STATION_BANK_SET_LOCATIONS,
      bankId,
      locations,
    })
  }
}

export function updateTimetable (timetableId, data) {
  return async (dispatch, getState) => {
    await timetableThunk.edit(timetableId, data, getState().auth.user.token)

    dispatch({
      type: TIMETABLE_UPDATE,
      timetableId,
      data,
    })
  }
}

export default function editReducer (state = { timetables: [], banks: [] }, action) {
  switch (action.type) {
    case BANK_LOAD:
      return {
        ...state,
        banks: [
          ...state.banks.filter(bank => {
            return bank.id !== action.bank.id
          }),
          action.bank,
        ],
      }
    case BANK_UPDATE:
      return {
        ...state,
        banks: state.banks.map(bank => ({
          ...bank,
          ...(bank.id === action.bankId ? action.data : {}),
        })),
      }
    case TIMETABLE_LOAD:
      return {
        ...state,
        timetables: [
          ...state.timetables.filter(timetable => {
            return timetable.id !== action.timetable.id
          }),
          action.timetable,
        ],
        banks: [
          ...state.banks.filter(bank => {
            return bank.id !== action.timetable.bank.id
          }),
          action.timetable.bank,
        ],
      }
    case STATION_BANK_SET_LOCATIONS:
      return {
        ...state,
        banks: state.banks.map(bank => {
          if (bank.id === action.bankId) {
            return { ...bank, locations: action.locations }
          } else {
            return bank
          }
        }),
      }
    case STATION_BANK_SET_SORT:
      return {
        ...state,
        banks: state.banks.map(bank => {
          if (bank.id === action.bankId) {
            return {
              ...bank,
              sort_priority: action.index,
            }
          } else {
            return bank
          }
        }),
      }
    case TIMETABLE_UPDATE:
      return {
        ...state,
        timetables: state.timetables.map(timetable => {
          if (timetable.id === action.timetableId) {
            return {
              ...timetable,
              ...action.data,
            }
          } else {
            return timetable
          }
        }),
      }
    default:
      return state
  }
}
