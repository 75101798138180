import { connect } from 'react-redux'

import { autoLogin } from 'store/auth'

import PageLayout from './PageLayout'

const mapStateToProps = (state) => ({
  isLoginModalOpen: state.auth.modalOpen,
  hasLoggedIn: Boolean(state.auth.user) || state.auth.hasExpired,
})

const mapDispatchToProps = (dispatch) => ({
  onTestLogin: () => {
    return dispatch(autoLogin())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout)
