import { createMuiTheme } from '@material-ui/core/styles'
import { teal } from '@material-ui/core/colors'

export default createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: teal,
    secondary: {
      main: '#6FB7A7',
    },
    grey: {
      dark: 'rgb(188, 188, 188)',
      main: 'rgb(246, 246, 246)',
      contrastText: '#666',
    },
  },
  shape: {
    borderRadius: 0,
  },
})
