/**
 * A stylized <Dialog /> component designed for user account actions
 * (e.g. login, reset password)
 */
import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'

import Times2Logo from 'styles/logo/times2-logo.svg'

import { HotKeys } from 'react-hotkeys'

const styles = () => ({
  dialogRoot: {
    padding: 0,
  },
  dialogDialog: {
    maxWidth: 448,
    minWidth: 400,
  },
  dialogContent: {
    padding: '100px 64px 32px',
    textAlign: 'center',
  },
  dialogActionsRoot: {
    margin: 0,
  },
  logo: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 64,
    height: 64,
  },
})

const propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  classes: PropTypes.object.isRequired,
}

const defaultProps = {
  onClose: () => {},
  actions: '',
}

const UserDialog = ({ open, children, actions, onSubmit, onClose, classes }) => {
  const keyboardShortcuts = { map: {}, handlers: {} }

  if (onSubmit) {
    keyboardShortcuts.map.login = 'enter'
    keyboardShortcuts.handlers.login = onSubmit
  }

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Slide} classes={{
      root: classes.dialogRoot,
      paper: classes.dialogDialog,
    }}>
      <img src={Times2Logo} className={classes.logo} />
      <DialogContent autoFocus classes={{ root: classes.dialogContent }}>
        <HotKeys handlers={keyboardShortcuts.handlers} keyMap={keyboardShortcuts.map}>
          {children}
        </HotKeys>
      </DialogContent>
      { actions &&
        <DialogActions classes={{
          root: classes.dialogActionsRoot,
        }}>
          { actions }
        </DialogActions>
      }
    </Dialog>
  )
}

UserDialog.propTypes = propTypes
UserDialog.defaultProps = defaultProps

export default withStyles(styles)(UserDialog)
