/* globals API_ROOT */
/**
 * Provides functions used by thunks to connect to the back-end API
 */

// Base url for endpoints
const baseUrl = API_ROOT

export function buildRequest (endpoint, body) {
  let data = new FormData()
  data.append('data', JSON.stringify(body))
  return new Request(baseUrl + endpoint, {
    method: 'POST',
    mode: 'cors',
    redirect: 'follow',
    body: data,
  })
}

export function makeRequest (endpoint, method, body) {
  return fetch(buildRequest(endpoint, method, body)).then(response => response)
}

export async function buildResponse (response) {
  // TODO fix json

  let data = await response.json()

  return {
    successful: true,
    data,
  }
}

export async function buildError (response, fallbackError) {
  let error = ''

  try {
    const json = await response.json()
    error = json.message
  } catch (e) {
    error = fallbackError
  }

  throw new Error(error)
}
