import stationThunk from 'thunks/classes/Station'
import timetableThunk from 'thunks/classes/Timetable'

import { FILTER_OPERATOR, FILTER_HEADCODE } from 'helpers/filters'
import { PUSH_NOTIFICATION } from './notifications'

/**
 * This store manages reference data for the application, such as stations and
 * global filters. Any data which related to a particular timetable or station
 * bank should be handled by the 'data' store.
 */

export const DATA_FILES_LOADED = 'DATA_FILES_LOADED'
export const LOADED_VALID_TOCS = 'LOADED_VALID_TOCS'
export const STATIONS_LOADED = 'STATIONS_LOADED'
export const STATION_ADDED = 'STATION_ADDED'
export const STATION_EDITED = 'STATION_EDITED'
export const FILTERS_LOADED = 'FILTERS_LOADED'
export const UNLOAD_REFERENCE_DATA = 'UNLOAD_REFERENCE_DATA'

function dateWithinHours (date, hours) {
  const past = date * 1
  const now = (new Date() * 1)
  return (now - past) < (hours * 3600 * 1000)
}

function dateIsToday (dateISO) {
  const now = new Date()
  const date = new Date(dateISO)
  return date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()
}

export function loadValidStations () {
  return async (dispatch, getState) => {
    // If we've got stations from within 24 hours, don't load them again
    const state = getState().reference
    if (state.stations.length && dateWithinHours(state.lastUpdated.stations, 24)) {
      return Promise.resolve()
    }

    // Load the most-up-date station list
    const { data } = await stationThunk.list(getState().auth.user.token)

    dispatch({
      type: STATIONS_LOADED,
      payload: data,
    })
  }
}

export function addStation (code, tiploc, name, data) {
  return async (dispatch, getState) => {
    await stationThunk.add(code, tiploc, name, data, getState().auth.user.token)

    dispatch({
      type: STATION_ADDED,
      data: {
        ...data,
        code,
        crs: code,
        name,
        detail: name,
      },
    })
  }
}

export function saveStation (code, data) {
  return async (dispatch, getState) => {
    await stationThunk.edit(code, data, getState().auth.user.token)

    dispatch({
      type: STATION_EDITED,
      code,
      data,
    })
  }
}

export function loadDataFiles () {
  return async (dispatch, getState) => {
    // If we've got data files from within 24 hours, don't load them again
    const state = getState().reference
    if (state.dataFiles.length && state.lastUpdated.dataFiles !== null && dateIsToday(state.lastUpdated.dataFiles)) {
      return
    }

    // Load the data files
    const { data } = await timetableThunk.dataList(getState().auth.user.token)

    dispatch({
      type: DATA_FILES_LOADED,
      payload: data,
    })
  }
}

export function loadValidTOCS () {
  return async (dispatch, getState) => {
    // If we've got tocs from within 48 hours, don't load them again
    const state = getState().reference
    if (Object.keys(state.tocs).length && dateWithinHours(state.lastUpdated.tocs, 48)) {
      return
    }

    dispatch({
      type: LOADED_VALID_TOCS,
      payload: stationThunk.tocs,
    })
  }
}

export function loadGlobalFilters () {
  return async (dispatch, getState) => {
    const loadFilters = [
      timetableThunk.filters.toc.list(getState().auth.user.token),
      timetableThunk.filters.headcode.list(getState().auth.user.token),
    ]

    const [ resultTocs, resultHeadcodes ] = await Promise.all(loadFilters)

    const tocFilters = resultTocs.data.map(filter => ({
      ...filter,
      type: {
        id: FILTER_OPERATOR,
        label: 'Operator',
      },
    }))

    const headcodeFilters = resultHeadcodes.data.map(filter => ({
      ...filter,
      type: {
        id: FILTER_HEADCODE,
        label: 'Headcode',
      },
    }))

    dispatch({
      type: FILTERS_LOADED,
      filters: [
        ...tocFilters,
        ...headcodeFilters,
      ],
    })
  }
}

export function createTocFilter (code, name) {
  return async (dispatch, getState) => {
    const { data } = await timetableThunk.filters.toc.create(code, name, getState().auth.user.token)

    dispatch({
      type: FILTERS_LOADED,
      filters: [{
        filter_id: data.filter_id,
        code,
        name,
        type: {
          id: FILTER_OPERATOR,
          label: 'Operator',
        },
      }],
    })

    return data.filter_id
  }
}

export function createHeadcodeFilter (headcode) {
  return async (dispatch, getState) => {
    try {
      const { data } = await timetableThunk.filters.headcode.create(headcode, `Filter for headcode ${headcode}`, getState().auth.user.token)

      dispatch({
        type: FILTERS_LOADED,
        filters: [{
          filter_id: data.filter_id,
          headcode,
          name: `Filter for headcode ${headcode}`,
          type: {
            id: FILTER_HEADCODE,
            label: 'Headcode',
          },
        }],
      })

      return data.filter_id
    } catch (err) {
      dispatch({
        type: PUSH_NOTIFICATION,
        payload: err.message,
      })
    }
  }
}

export function forceRefresh () {
  return async (dispatch, getState) => {
    dispatch({
      type: UNLOAD_REFERENCE_DATA,
    })

    await Promise.all([
      dispatch(loadDataFiles()),
      dispatch(loadValidTOCS()),
      dispatch(loadValidStations()),
    ])
  }
}

const INITIAL_STATE = {
  dataFiles: [{ id: 0, name: 'Loading', curr_ref: '...' }],
  tocs: [],
  stations: [],
  filters: [],
  lastUpdated: {
    dataFiles: null,
    tocs: null,
    stations: null,
  },
}

export default function fileReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case UNLOAD_REFERENCE_DATA:
      return {
        ...state,
        lastUpdated: {
          dataFiles: null,
          tocs: null,
          stations: null,
        },
      }
    case DATA_FILES_LOADED:
      return {
        ...state,
        dataFiles: action.payload,
        lastUpdated: { ...state.lastUpdated, dataFiles: (new Date()).toISOString() },
      }
    case LOADED_VALID_TOCS:
      return {
        ...state,
        tocs: action.payload,
        lastUpdated: { ...state.lastUpdated, tocs: (new Date()).toISOString() },
      }
    case STATIONS_LOADED:
      return {
        ...state,
        stations: action.payload.sort((a, b) => a.code.localeCompare(b.code)),
        lastUpdated: { ...state.lastUpdated, stations: (new Date()).toISOString() },
      }
    case STATION_ADDED:
      return {
        ...state,
        stations: [...state.stations, action.data].sort((a, b) => a.code.localeCompare(b.code)),
      }
    case STATION_EDITED:
      return {
        ...state,
        stations: state.stations.map(station => {
          if (station.code === action.code) {
            return Object.assign({}, station, action.data)
          } else {
            return station
          }
        }),
      }
    case FILTERS_LOADED: {
      const filters = (state.filters || []).slice(0)

      action.filters.forEach(filter => {
        const existingFilterIndex = filters.findIndex(x => x.filter_id === filter.filter_id)
        if (existingFilterIndex > -1) {
          filters[existingFilterIndex] = filter
        } else {
          filters.push(filter)
        }
      })

      return {
        ...state,
        filters,
      }
    }
    default:
      return state
  }
}
