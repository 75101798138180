import { connect } from 'react-redux'
import { dismissNotification } from 'store/notifications'

import Notification from '../components/Notification'

const mapStateToProps = (state) => ({
  notification: state.notifications[0] || '',
})

const mapDispatchToProps = (dispatch) => ({
  onDismissNotification: () => {
    return dispatch(dismissNotification())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
